<section class="our-experts-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="our-experts-image">
                    <img src="assets/img/experts-img.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="our-experts-content">
                    <div class="content">
                        <span class="sub-title">Our Experts</span>
                        <h2>Meet our leadership game development team</h2>
                        <a routerLink="/team" class="default-btn">View Team</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>