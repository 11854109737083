<app-navbar-style-one></app-navbar-style-one>

<section class="magazine-demo bg-image jarallax" data-jarallax='{"speed": 0.3}'>

    <app-magazine-main-banner></app-magazine-main-banner>

    <app-magazine-news></app-magazine-news>

</section>

<footer class="footer-area ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>