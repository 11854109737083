<div class="d-flex flex-column bg-control">
    <app-header></app-header>


    <div class="coming-soon-control">
        <h1 class="text-coming-soon"> Coming soon ....</h1>
    </div>

    <app-footer>
        
    </app-footer>

</div>