import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-matches-style-two',
  templateUrl: './matches-style-two.component.html',
  styleUrls: ['./matches-style-two.component.scss']
})
export class MatchesStyleTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
