<div class="banner-area">
    <div class="container">
        <div class="banner-content">
            <span class="sub-title">Enjoy The magazine</span>
            <div class="logo">
                <img src="assets/img/zelda.png" alt="image">
            </div>
            <h6>Esports magazine</h6>
        </div>
    </div>
</div>