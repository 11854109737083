<div class="d-flex flex-column bg-control">
    <app-header></app-header>

    <div class="card-control">

        <div class="card-green-control">
            <div class="grid-control">
                <div class="border-img-control">
                    <img src="./assets/img/our-guest/card-1-img.png" class="img-control">

                </div>
               
                <div class="d-flex flex-column right-control">
                    <div class="text-top-control ">
                        <span class="text-header">
                            Hotel de Mentía : <br class="d-none d-md-block">
                            The Novelty
                        </span>

                        <span class="text-header text-header-right">2,000</span>
                    </div>
                    <span class="text-desc">
                        The overall theme of the collection is to show the current guests of Hotel de Mentía. These guests are considered ‘The Novelty’ since they are the very first group of guests to ever visit & stay at the hotel. 
                        <br><br>
                        With exclusive utilities, Holders of this collection will be rewarded most out of all the collections.
                    </span>

                    <div class="button-control d-flex">

                        <img src="./assets/img/our-guest/button-magic-eden.png" (click)="clickEden()" class="button-cream">
                        <img src="./assets/img/our-guest/button-solanart.png" (click)="clickSolanart()" class="button-cream">
                    </div>
                </div>
            </div>

        </div>
        <div class="card-brown-control">
            <div class="grid-control">
                <div class="border-img-control">
                    <img src="./assets/img/our-guest/card-2-img.png" class="img-control">

                </div>
                <div class="d-flex flex-column right-control">
                    <div class="text-top-control ">
                        <span class="text-header">
                            Hotel de Mentía : <br>
                            The INVIATION
                        </span>

                        <span class="text-header text-header-right">1,237</span>
                    </div>
                    <span class="text-desc">
                        The overall theme of the collection is the journey of guests who are traveling to Hotel de Mentía. These guests who have all been faced with sorrow & disappointment have all been invited to join the novelty guests who are waiting patiently.
                    </span>

                    <div class="button-control d-flex">

                        <img src="./assets/img/our-guest/button-magic-eden.png" (click)="clickEden2()" class="button-cream">
                        <img src="./assets/img/our-guest/button-solanart.png" (click)="clickSolanart2()" class="button-cream">
                    </div>
                </div>
            </div>

        </div>
    </div>

    <app-footer>
        
    </app-footer>

</div>