<div class="container">
    <div class="footer-content">
        <div class="logo">
            <!-- <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            
             <ul class="footer-menu">
                 <li class="nav-item"><a class="nav-link" routerLink="/">Legal</a></li>
                 <li class="nav-item"><a class="nav-link" routerLink="/">Terms of Use</a></li>
                 <li class="nav-item"><a class="nav-link" routerLink="/">Privacy policy</a></li>
                 <li class="nav-item"><a class="nav-link" routerLink="/">Cockie setting</a></li>
                 <li class="nav-item"><a class="nav-link" routerLink="/">Support center</a></li>
             </ul>-->
         </div>
     </div>
 </div>

 <div class="copyright-area">
     <div class="container">
         <div class="row align-items-center">
             <div class="col-lg-6 col-md-6 col-sm-5">
                 <p><i class='bx bx-copyright'></i>2022 <a routerLink="/">Hoteldementia</a> GAME NFT</p>
             </div>
<!--
             <div class="col-lg-6 col-md-6 col-sm-7">
                 <div class="lang-switcher">
                     <span>Select your language</span>
                     <select>
                         <option>English</option>
                         <option>Spanish</option>
                         <option>French</option>
                         <option>Portuguese</option>
                     </select>
                 </div>
             </div>
             -->
         </div>
     </div>
 </div>
