<div class="d-flex flex-column footer-control">

    <div class="container grid-bottom">
        <div class="d-flex flex-column">

            <span class="text-our-marketplace">Our Marketplace</span>

            <div class="button-marketplace-control">

                <img src="./assets/img/landing/magic-eden.png" (click)="clickEden()" class="button-marketplace">
                <img src="./assets/img/landing/solanart.png" (click)="clickSolanart()" class="button-marketplace">
            </div>

        </div>
        <div class="d-flex flex-column">
            <span class="text-our-marketplace">CHECK IT NOW</span>

            <div class="social-grid-control">

                <img src="./assets/img/landing/ic-discord.png" (click)="clickDiscord()" class="social-icon">
                <img src="./assets/img/landing/ic-twitter.png" (click)="clickTwitter()" class="social-icon">
                <img src="./assets/img/landing/ic-facebook.png" (click)="clickFacebook()" class="social-icon">
                <img src="./assets/img/landing/ic-ig.png" (click)="clickIg()" class="social-icon">
            </div>
        </div>

    </div>

</div>