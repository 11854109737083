import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsors-page',
  templateUrl: './sponsors-page.component.html',
  styleUrls: ['./sponsors-page.component.scss']
})
export class SponsorsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
