<div class="home-slides owl-carousel owl-theme">
    <div class="single-banner-item banner-bg1">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy The Game</span>
                        <div class="logo">
                            <img src="assets/img/zelda.png" alt="image">
                        </div>
                        <h6>Available Now</h6>
                        <div class="btn-box">
                            <a routerLink="/" class="default-btn">Purchase Now</a>
                            <a routerLink="/contact" class="optional-btn">Free Trial</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/banner-img1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-banner-item banner-bg2">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy The Game</span>
                        <div class="logo">
                            <img src="assets/img/zelda.png" alt="image">
                        </div>
                        <h6>Available Now</h6>
                        <div class="btn-box">
                            <a routerLink="/" class="default-btn">Purchase Now</a>
                            <a routerLink="/contact" class="optional-btn">Free Trial</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/banner-img2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-banner-item banner-bg3">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy The Game</span>
                        <div class="logo">
                            <img src="assets/img/zelda.png" alt="image">
                        </div>
                        <h6>Available Now</h6>
                        <div class="btn-box">
                            <a routerLink="/" class="default-btn">Purchase Now</a>
                            <a routerLink="/contact" class="optional-btn">Free Trial</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/banner-img3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>