<div class="container">
    <div class="section-title">
        <span class="sub-title">Team</span>
        <h2>Top ranking team</h2>
    </div>

    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-top-team-item">
                <img src="assets/img/top-team-img1.png" alt="image">
                <h3><a routerLink="/single-team">Fierce</a></h3>
                <ul>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitch'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-vimeo'></i></a></li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-top-team-item">
                <img src="assets/img/top-team-img2.png" alt="image">
                <h3><a routerLink="/single-team">Devlis</a></h3>
                <ul>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitch'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-vimeo'></i></a></li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-top-team-item">
                <img src="assets/img/top-team-img3.png" alt="image">
                <h3><a routerLink="/single-team">Skulking</a></h3>
                <ul>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitch'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-vimeo'></i></a></li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-top-team-item">
                <img src="assets/img/top-team-img4.png" alt="image">
                <h3><a routerLink="/single-team">Gurdian</a></h3>
                <ul>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitch'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-vimeo'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>