<div class="container">
    <div class="section-title">
        <span class="sub-title"></span>
        <span style="font-family:Merriweather-Regular; src: local(Merriweather-Regular) ;color:#ffffff;text-transform:uppercase;font-size:25px">Check in
        </span>
    </div>

    <div class="row">
        <!--
        <div class="col-lg-2 col-sm-4 col-md-4 col-5 mx-auto">
            <div class="single-social-box">
                <div class="content">
                    <i class='bx bxl-twitch'></i>
                    Twitch
                </div>
                <div class="shape">
                    <img src="assets/img/social-shape1.png" alt="image">
                    <img src="assets/img/social-shape2.png" alt="image">
                </div>
                <a href="#" target="_blank" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-2 col-sm-4 col-md-4 col-5 mx-auto">
            <div class="single-social-box">
                <div class="content">
                    <i class='bx bxl-facebook'></i>
                    Facebook
                </div>
                <div class="shape">
                    <img src="assets/img/social-shape1.png" alt="image">
                    <img src="assets/img/social-shape3.png" alt="image">
                </div>
                <a href="#" target="_blank" class="link-btn"></a>
            </div>
        </div>
        -->
        <div class="col-lg-2 col-sm-4 col-md-4 col-5 mx-auto">
            <div class="single-social-box">
                <div class="content">
                    <i class='bx bxl-twitter'></i>
                    Twitter
                </div>
                <div class="shape">
                    <img src="assets/img/social-shape1.png" alt="image">
                    <img src="assets/img/social-shape4.png" alt="image">
                </div>
                <a href="https://twitter.com/hoteldementia" target="_blank" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-2 col-sm-4 col-md-4 col-5 mx-auto">
            <div class="single-social-box">
                <div class="content">
                    <i class='bx bxl-discord'></i>
                   Discord
                </div>
                <div class="shape">
                    <img src="assets/img/social-shape1.png" alt="image">
                    <img src="assets/img/social-shape5.png" alt="image">
                </div>
                <a href="https://discord.gg/tvjfhQbkky" target="_blank" class="link-btn"></a>
            </div>
        </div>
        <!--
                <div class="col-lg-2 col-sm-4 col-md-4 col-5 mx-auto">
                    <div class="single-social-box">
                        <div class="content">
                            <i class='bx bxl-instagram'></i>
                            Instagram
                        </div>
                        <div class="shape">
                            <img src="assets/img/social-shape1.png" alt="image">
                            <img src="assets/img/social-shape6.png" alt="image">
                        </div>
                        <a href="#" target="_blank" class="link-btn"></a>
                    </div>
                </div>


                <div class="col-lg-2 col-sm-4 col-md-4 col-6">
                    <div class="single-social-box">
                        <div class="content">
                            <i class='bx bxl-vimeo'></i>
                            Vimeo
                        </div>
                        <div class="shape">
                            <img src="assets/img/social-shape1.png" alt="image">
                            <img src="assets/img/social-shape7.png" alt="image">
                        </div>
                        <a href="#" target="_blank" class="link-btn"></a>
                    </div>
                </div>
                -->
    </div>
</div>
