<div class="container">
    <div class="section-title">
        <span class="sub-title">Live</span>
        <h2>Trending Streams</h2>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-live-stream-item">
                <img src="assets/img/trending-img1.jpg" alt="image">

                <div class="content">
                    <h3>Fortnite Light</h3>
                    <ul class="meta">
                        <li>Youtube</li>
                        <li>Online</li>
                    </ul>
                </div>

                <a href="#" class="video-btn"><i class="flaticon-play-button"></i></a>
                <a href="#" target="_blank" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-live-stream-item">
                <img src="assets/img/trending-img2.jpg" alt="image">

                <div class="content">
                    <h3>Apex Legends</h3>
                    <ul class="meta">
                        <li>Vimeo</li>
                        <li>Online</li>
                    </ul>
                </div>

                <a href="#" class="video-btn"><i class="flaticon-play-button"></i></a>
                <a href="#" target="_blank" class="link-btn"></a>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div class="single-live-stream-item">
                <img src="assets/img/trending-img3.jpg" alt="image">

                <div class="content">
                    <h3>Past Cure</h3>
                    <ul class="meta">
                        <li>Twitch</li>
                        <li>Offline</li>
                    </ul>
                </div>

                <a href="#" class="video-btn"><i class="flaticon-play-button"></i></a>
                <a href="#" target="_blank" class="link-btn"></a>
            </div>
        </div>
    </div>
</div>