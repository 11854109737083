<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Masonry">Masonry</h1>
        </div>
    </div>
</section>

<div class="magazine-area">
    <div class="container-fluid">
        <div class="magazine-items">
            <div class="col-lg-3 col-md-6 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img1.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Game</li>
                            <li>June 12, 2020</li>
                        </ul>
                        <h3>Android tools for mobile game development</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img2.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Programmer</li>
                            <li>June 11, 2020</li>
                        </ul>
                        <h3>Get a job: Disbelief is hiring a Programmer</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img3.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Spider-Man</li>
                            <li>June 10, 2020</li>
                        </ul>
                        <h3>Don't Miss: Making Insomniac's Spider-Man do what a spider can</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img5.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Mobile</li>
                            <li>June 09, 2020</li>
                        </ul>
                        <h3>How to hit the mark with mobile games</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img7.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Fails</li>
                            <li>June 08, 2020</li>
                        </ul>
                        <h3>Game Translation Fails - Common Causes</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img4.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Borderlands</li>
                            <li>June 07, 2020</li>
                        </ul>
                        <h3>Borderlands 3 Hotfix Update Is Here</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img6.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Game</li>
                            <li>June 06, 2020</li>
                        </ul>
                        <h3>Zelda comes to Game Pass for its 10th anniversary</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img8.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Hardware</li>
                            <li>June 05, 2020</li>
                        </ul>
                        <h3>What’s New In Matter of Hardware This Year?</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img9.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>BaaS</li>
                            <li>June 04, 2020</li>
                        </ul>
                        <h3>BaaS MPlayer Services Mega Comparison Sheet</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 grid-item">
                <div class="single-magazine-box">
                    <img src="assets/img/magazine-img10.jpg" alt="image">

                    <div class="content">
                        <ul class="meta">
                            <li>Windows</li>
                            <li>June 03, 2020</li>
                        </ul>
                        <h3>How To Make The Windows 10 Bootable?</h3>
                        <a routerLink="/single-blog" class="read-more-btn"><i class="flaticon-null"></i> Read More</a>
                    </div>

                    <a routerLink="/single-blog" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>