<app-navbar-style-one></app-navbar-style-one>

<app-tournaments-main-banner></app-tournaments-main-banner>

<section class="popular-leagues-area pt-100 pb-70">
    <app-popular-leagues></app-popular-leagues>
</section>

<section class="upcoming-tournaments-area pb-100">
    <app-upcoming-tournaments></app-upcoming-tournaments>
</section>

<app-matches-style-two></app-matches-style-two>

<section class="live-stream-area bg-color pt-100 pb-70">
    <app-trending-streams></app-trending-streams>
</section>

<section class="top-team-area pb-70">
    <app-top-ranking-team-style-two></app-top-ranking-team-style-two>
</section>

<footer class="footer-area">
    <app-footer-style-one></app-footer-style-one>
</footer>