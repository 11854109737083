<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Gallery">Gallery</h1>
        </div>
    </div>
</section>

<section class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img1.jpg">
                        <img src="assets/img/main-blog-img1.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img2.jpg">
                        <img src="assets/img/main-blog-img2.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img3.jpg">
                        <img src="assets/img/main-blog-img3.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img4.jpg">
                        <img src="assets/img/main-blog-img4.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img5.jpg">
                        <img src="assets/img/main-blog-img5.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img6.jpg">
                        <img src="assets/img/main-blog-img6.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img7.jpg">
                        <img src="assets/img/main-blog-img7.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img8.jpg">
                        <img src="assets/img/main-blog-img8.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/main-blog-img9.jpg">
                        <img src="assets/img/main-blog-img9.jpg" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>