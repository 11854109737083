<div class="banner-wrapper-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="banner-wrapper-content">
            <div class="logo">
                <img src="assets/img/zelda.png" alt="image">
            </div>
            <h1>Tournaments Home</h1>
            <span class="sub-title">Enjoy The tournament</span>
        </div>
    </div>
</div>