<div class="container">
    <div class="section-title">
        <span class="sub-title" style="font-family:Soria ;" >Our Valuable Partners</span>
    </div>
    
    <div class="partner-slides owl-carousel owl-theme">
        <div class="single-partner-item">
            <a href="#" class="d-block"><img src="assets/img/partner-img1.png" alt="image"></a>
        </div>

        <div class="single-partner-item">
            <a href="#" class="d-block"><img src="assets/img/partner-img2.png" alt="image"></a>
        </div>

        <div class="single-partner-item">
            <a href="#" class="d-block"><img src="assets/img/partner-img3.png" alt="image"></a>
        </div>

        <div class="single-partner-item">
            <a href="#" class="d-block"><img src="assets/img/partner-img4.png" alt="image"></a>
        </div>

        <div class="single-partner-item">
            <a href="#" class="d-block"><img src="assets/img/partner-img5.png" alt="image"></a>
        </div>
    </div>
</div>
