<div class="d-flex flex-column bg-control">
    <app-header></app-header>
    <div class="container d-flex flex-column container-control" *ngIf="!isShowMobile">
        <span class="text-header">HOTEL DE MENTIA <br> VISUAL NOVEL</span>
    </div>
    <div class="container d-md-flex d-none flex-column" *ngIf="!isShowMobile">


        <div class="card-control">
            <img src="./assets/img/visual-novel/card-1.png" class="card-item" data-bs-toggle="modal"
                data-bs-target="#exampleModal">
            <img src="./assets/img/visual-novel/card-2.png" class="card-item">
            <img src="./assets/img/visual-novel/card-3.png" class="card-item">
        </div>
        <div class="card-control mx-auto">
            <img src="./assets/img/visual-novel/card-4.png" class="card-item">
            <img src="./assets/img/visual-novel/card-5.png" class="card-item">
        </div>
    </div>

    <ngb-carousel class="carousel-control  d-block d-md-none" [showNavigationIndicators]="false" *ngIf="!isShowMobile">
        <ng-template ngbSlide>
            <div class="d-flex">
                <img src="./assets/img/visual-novel/card-1.png" class="card-item" (click)="onClickSeason()">
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="d-flex">
                <img src="./assets/img/visual-novel/card-2.png" class="card-item">
            </div>

        </ng-template>
        <ng-template ngbSlide>
            <div class="d-flex">
                <img src="./assets/img/visual-novel/card-3.png" class="card-item">
            </div>

        </ng-template>
        <ng-template ngbSlide>
            <div class="d-flex">
                <img src="./assets/img/visual-novel/card-4.png" class="card-item">
            </div>

        </ng-template>
        <ng-template ngbSlide>
            <div class="d-flex">
                <img src="./assets/img/visual-novel/card-5.png" class="card-item">
            </div>

        </ng-template>

    </ngb-carousel>



    <div class="container d-flex d-md-none flex-column container-control" *ngIf="isShowMobile">
        <img src="./assets/img/visual-novel/card-1.png" class="card-item">
        <div class="text-control d-flex flex-column">
            <span class="text-season">SEASON 1</span>
            <span class="text-ep">CHIPS OF <br> MEMORIES</span>
            <span class="text-season">8 EPISODES</span>

            <div class="text-bottom-control d-flex flex-column">
                <span class="text-desc">
                    A prodigy scholarship student plagiarized her way to fame and got caught. Her
                    reputation and future tarnished in single night. She fled on stolen car but her
                    journey takes an unexpected turn when she discover a mysterious hotel promised
                    to erase her dark past.
                </span>

                <img src="./assets/img/visual-novel/start-button.png" data-bs-dismiss="modal" aria-label="Close"
                    routerLink="/choose-ep" class="start-button">
            </div>
        </div>
    </div>
    <div class="divider-black-gradient"></div>
    <div class="footer-bg">
        <app-footer></app-footer>
    </div>

    <!-- Modal -->
    <div class="modal fade" *ngIf="!isShowMobile" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body my-auto">

                    <div class="d-flex  modal-control">

                        <div class="grid-control">
                            <div class="d-flex">
                                <img src="./assets/img/visual-novel/card-1-popup.png" class="card-img">
                            </div>

                            <div class="text-control d-flex flex-column">
                                <span class="text-season">SEASON 1</span>
                                <span class="text-ep">CHIPS OF <br> MEMORIES</span>
                                <span class="text-season">8 EPISODES</span>

                                <div class="text-bottom-control d-flex flex-column">
                                    <span class="text-desc">
                                        A prodigy scholarship student plagiarized her way to fame and got caught. Her
                                        reputation and future tarnished in single night. She fled on stolen car but her
                                        journey takes an unexpected turn when she discover a mysterious hotel promised
                                        to erase her dark past.
                                    </span>

                                    <img src="./assets/img/visual-novel/start-button.png" data-bs-dismiss="modal"
                                        aria-label="Close" routerLink="/choose-ep" class="start-button">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>