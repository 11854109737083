<div class="d-flex flex-column  bg-control">
    <app-header></app-header>
    <div class="section-1-control d-flex flex-column">
        <div class="section-2-control d-flex flex-column">
            <div class="container text-control d-flex flex-column">
                <span class="text-white">
                    Hotel de Mentía is a Web 3.0 NFT game project with Social-Fi and Game-Fi elements. <br><br>
    
                    Users own NFT Avatars that are utilized in the game, challenging their thoughts, mistakes & consequences
                    <br>
                    while learning the mysteries of the hotel & fighting<br>
                    the 5 stages of guilt. In the same time, players will be able to collect<br>
                    valuable items and earn daily tokens.<br><br>
    
                    Players enjoy not only the storytelling but also the ability to<br>
                    upgrade their unique NFT characters & rooms.<br><br>
    
                    A metaverse will also be available for users to interact,<br>
                    play minigames, trade & confide with other players in the game.<br><br>
                </span>
            </div>
    
        </div>
    </div>
   
    <div class="section-9-control">
        <div class="container d-flex flex-column">
            <span class="text-white">MORE ABOUT PROJECT</span>
            <img src="./assets/img/landing/button-lite-paper.png" (click)="clickLitePaper()"
                class="lite-paper-button mx-auto">
        </div>
    </div>
   
    <div class="section-8-control d-flex flex-column">

        <div class="container d-flex flex-column">


            <span class="text-header">
                CONCIERGE
            </span>

            <div class="grid-control">

                <div class="item-control">
                    <img src="./assets/img/about-us/team-1.png" class="profile-img">
                    <span class="profile-name">RIETY</span>
                    <span class="profile-position">Founder & Main Artist</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/about-us/team-2.png" class="profile-img">
                    <span class="profile-name">PERAKARN</span>
                    <span class="profile-position">Project Manager</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/about-us/team-3.png" class="profile-img">
                    <span class="profile-name">FLINTSTONE</span>
                    <span class="profile-position">Community Manager</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/about-us/team-4.png" class="profile-img">
                    <span class="profile-name">GHOZT</span>
                    <span class="profile-position">Blockchain Developer</span>
                </div>
            </div>
            <div class="grid-control">

                <div class="item-control">
                    <img src="./assets/img/about-us/team-5.png" class="profile-img">
                    <span class="profile-name">ZTEVE</span>
                    <span class="profile-position">Blockchain Developer</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/about-us/team-6.png" class="profile-img">
                    <span class="profile-name">DOW</span>
                    <span class="profile-position">Game Developer</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/about-us/team-7.png" class="profile-img">
                    <span class="profile-name">FONE</span>
                    <span class="profile-position">Designer & 3D Generalist</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/about-us/team-8.png" class="profile-img">
                    <span class="profile-name">HOUSE</span>
                    <span class="profile-position">Sound Creative & Social Media Manager</span>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>