import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trending-streams',
  templateUrl: './trending-streams.component.html',
  styleUrls: ['./trending-streams.component.scss']
})
export class TrendingStreamsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
