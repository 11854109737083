<div class="container">
    <div class="section-title">
        <span class="sub-title">Game</span>
        <h2>Find our games on appstore</h2>
    </div>
</div>

<div class="container-fluid">
    <div class="games-slides owl-carousel owl-theme">
        <div class="single-games-box">
            <img src="assets/img/games-img1.jpg" alt="image">

            <div class="content">
                <h3>Fither Hors</h3>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="btn-box">
                    <a target="_blank" href="#" class="playstore-btn">
                        <img src="assets/img/playstore.png" alt="image">
                        <span>Get it on</span>
                        Google Play
                    </a>
                    <a target="_blank" href="#" class="applestore-btn">
                        <img src="assets/img/appstore.png" alt="image">
                        <span>Get it on</span>
                        Apple Store
                    </a>
                </div>
            </div>
        </div>

        <div class="single-games-box">
            <img src="assets/img/games-img2.jpg" alt="image">

            <div class="content">
                <h3>Rabel cat</h3>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="btn-box">
                    <a target="_blank" href="#" class="playstore-btn">
                        <img src="assets/img/playstore.png" alt="image">
                        <span>Get it on</span>
                        Google Play
                    </a>
                    <a target="_blank" href="#" class="applestore-btn">
                        <img src="assets/img/appstore.png" alt="image">
                        <span>Get it on</span>
                        Apple Store
                    </a>
                </div>
            </div>
        </div>

        <div class="single-games-box">
            <img src="assets/img/games-img3.jpg" alt="image">

            <div class="content">
                <h3>Royal robot</h3>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="btn-box">
                    <a target="_blank" href="#" class="playstore-btn">
                        <img src="assets/img/playstore.png" alt="image">
                        <span>Get it on</span>
                        Google Play
                    </a>
                    <a target="_blank" href="#" class="applestore-btn">
                        <img src="assets/img/appstore.png" alt="image">
                        <span>Get it on</span>
                        Apple Store
                    </a>
                </div>
            </div>
        </div>

        <div class="single-games-box">
            <img src="assets/img/games-img2.jpg" alt="image">

            <div class="content">
                <h3>Rabel cat</h3>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="btn-box">
                    <a target="_blank" href="#" class="playstore-btn">
                        <img src="assets/img/playstore.png" alt="image">
                        <span>Get it on</span>
                        Google Play
                    </a>
                    <a target="_blank" href="#" class="applestore-btn">
                        <img src="assets/img/appstore.png" alt="image">
                        <span>Get it on</span>
                        Apple Store
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>