<app-navbar-style-one></app-navbar-style-one>

<app-bosting-main-banner></app-bosting-main-banner>

<section class="services-area pt-100 pb-100">
    <app-services></app-services>
</section>

<div class="partner-area bg-1b060a pt-100 pb-70">
    <app-partner></app-partner>
</div>

<section class="featured-games-area ptb-100">
    <app-featured-games></app-featured-games>
</section>

<section class="games-area pb-100">
    <app-games></app-games>
</section>

<app-our-experts></app-our-experts>

<section class="feedback-area pt-100 pb-70">
    <app-feedback></app-feedback>
</section>

<section class="blog-area pb-100">
    <app-news></app-news>
</section>

<app-subscribe-style-two></app-subscribe-style-two>

<app-history></app-history>

<app-footer-style-two></app-footer-style-two>