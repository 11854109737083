<div class="container">
    <div class="section-title">
        <span class="sub-title">Blog</span>
        <h2>News And Updates</h2>
    </div>

    <div class="blog-slides-two owl-carousel owl-theme">
        <div class="single-blog-post">
            <div class="post-image">
                <a routerLink="/single-blog" class="d-block">
                    <img src="assets/img/blog-img1.png" alt="image">
                </a>
            </div>

            <div class="post-content">
                <span class="sub-title">Release Note</span>
                <h3><a routerLink="/single-blog">ANDROID TOOLS FOR MOBILE GAME</a></h3>
                <a routerLink="/single-blog" class="default-btn">Learn More</a>
            </div>
        </div>

        <div class="single-blog-post">
            <div class="post-image">
                <a routerLink="/single-blog" class="d-block">
                    <img src="assets/img/blog-img2.png" alt="image">
                </a>
            </div>

            <div class="post-content">
                <span class="sub-title">Release Note</span>
                <h3><a routerLink="/single-blog">CONVERSION RATE THE SALES FUNNEL</a></h3>
                <a routerLink="/single-blog" class="default-btn">Learn More</a>
            </div>
        </div>

        <div class="single-blog-post">
            <div class="post-image">
                <a routerLink="/single-blog" class="d-block">
                    <img src="assets/img/blog-img3.png" alt="image">
                </a>
            </div>

            <div class="post-content">
                <span class="sub-title">Release Note</span>
                <h3><a routerLink="/single-blog">HOW TO HIT THE MARK WITH MOBILE GAMES</a></h3>
                <a routerLink="/single-blog" class="default-btn">Learn More</a>
            </div>
        </div>

        <div class="single-blog-post">
            <div class="post-image">
                <a routerLink="/single-blog" class="d-block">
                    <img src="assets/img/blog-img2.png" alt="image">
                </a>
            </div>

            <div class="post-content">
                <span class="sub-title">Release Note</span>
                <h3><a routerLink="/single-blog">CONVERSION RATE THE SALES FUNNEL</a></h3>
                <a routerLink="/single-blog" class="default-btn">Learn More</a>
            </div>
        </div>
    </div>
</div>