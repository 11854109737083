<div class="container-fluid container-div d-flex">

    <div class="frame-control d-flex">
        <img src="./assets/img/novel/choose-ep-bg.jpg" class="img-frame" />
        <div class="d-flex flex-column element-control">
           
            <div class="ep-control">
                <img src="./assets/img/novel/episode-text.png" class="ep-text">
                <div class="ep-grid-control">
                    <img src="./assets/img/novel/ep-1.png" (click)="clicktoEP('1')" class="ep-img">
                    <img src="./assets/img/novel/connect-ep.png" class="connect-img">
                    <img src="./assets/img/novel/ep-2.png" (click)="clicktoEP('2')"   class="ep-img">
                    <img src="./assets/img/novel/connect-ep.png" class="connect-img">
                    <img src="./assets/img/novel/ep-3.png" (click)="clicktoEP('3')" class="ep-img">
                  
                    <img src="./assets/img/novel/connect-ep.png" class="connect-img">
                    <img src="./assets/img/novel/ep-4.png" (click)="clicktoEP('4')"  class="ep-img">
                </div>
                <div class="connect-2-control">
                    <div class="connect-2-border">
                        <img src="./assets/img/novel/connect-ep-2.png" class="connect-img-2 mx-auto">
                    </div>
                  
                </div>
                
                <div class="ep-grid-control">
                   
                    <img src="./assets/img/novel/ep-8.png" (click)="clicktoEP('8')" class="ep-img">
                    <img src="./assets/img/novel/connect-ep.png" class="connect-img">
                    <img src="./assets/img/novel/ep-7.png" (click)="clicktoEP('7')"class="ep-img ">
                    <img src="./assets/img/novel/connect-ep.png" class="connect-img">
                    <img src="./assets/img/novel/ep-6.png" (click)="clicktoEP('6')"class="ep-img">
                    <img src="./assets/img/novel/connect-ep.png" class="connect-img">
                    <img src="./assets/img/novel/ep-5.png" (click)="clicktoEP('5')" class="ep-img">
                </div>
            </div>
        </div>
        <div class="opacity-frame"></div>
    </div>
</div>