<div class="container">
    <div class="section-title">
        <span class="sub-title">Testimonials</span>
        <h2>Ourplayers Feedback</h2>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 ">
            <div class="single-feedback-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                <span>John Doe</span>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 ">
            <div class="single-feedback-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                <span>David Warner</span>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div class="single-feedback-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                <span>Sarah Taylor</span>
            </div>
        </div>
    </div>
</div>