<div class="banner-area banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="banner-content">
            <span class="sub-title">Enjoy The Streaming</span>
            <div class="logo">
                <img src="assets/img/zelda.png" alt="image">
            </div>
            <h6>Esports Streaming</h6>
        </div>
    </div>
</div>