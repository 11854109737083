<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Products List">Products List</h1>
        </div>
    </div>
</section>

<section class="products-area ptb-100">
    <div class="container">
        <div class="zinble-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>Showing 1–8 of 12 results</p>
            </div>

            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img1.png" alt="image" class="main-image">

                        <a routerLink="/products-list" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <span class="hot">Hot!</span>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">Gaming Headphone</a></h3>
                        <span class="price">$99.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img2.png" alt="image" class="main-image">

                        <a routerLink="/products-list" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <span class="sale">Sale!</span>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">Gaming Chair</a></h3>
                        <span class="price">$199.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img3.png" alt="image" class="main-image">

                        <a routerLink="/products-list" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">Gaming Mouse</a></h3>
                        <span class="price">$69.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img4.png" alt="image" class="main-image">

                        <a routerLink="/products-list" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">Gaming Pad</a></h3>
                        <span class="price">$299.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img7.png" alt="image" class="main-image">

                        <a routerLink="/products-list" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">REAL MOUSE</a></h3>
                        <span class="price">$69.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img8.png" alt="image" class="main-image">

                        <a routerLink="/products-list" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">REAL KEYBOARD</a></h3>
                        <span class="price">$299.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img5.png" alt="image" class="main-image">

                        <a routerLink="/products-list" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <span class="hot">Hot!</span>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">MICROPFONE</a></h3>
                        <span class="price">$99.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img6.png" alt="image" class="main-image">

                        <a routerLink="/products-list" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <span class="sale">Sale!</span>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">REAL HEADPHONE</a></h3>
                        <span class="price">$199.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/products-list-2" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/products-list-2" class="page-numbers">2</a>
                    <a routerLink="/products-list-2" class="page-numbers">3</a>
                    <a routerLink="/products-list-2" class="page-numbers">4</a>
                    <a routerLink="/products-list-2" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>