<div class="d-flex flex-column">
    <app-header></app-header>
    <img src="./assets/img/landing/section-1-img.jpg" class="d-none d-md-block section-1-img" />
    <img src="./assets/img/landing/section-1-img-mobile.jpeg" class="d-block d-md-none section-1-img" />
    <div class="section-2-control d-flex flex-column">

        <img src="./assets/img/landing/section-2-bg.png" class="d-none d-md-block bg-img" />
        <img src="./assets/img/landing/section-2-bg.png" class="d-block d-md-none bg-img" />
        <div class="container text-control d-flex flex-column">
            <span class="text-white-invite">Are you going <br> through a difficult<br> time right now?</span>
            <span class="text-white-invite-1">Please sit down and relax.</span>
            <!-- <div class="border-video">
                <vg-player>

                    <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
                        <source src="./assets/img/hotelvideo.mp4" type="video/mp4">
                    </video>
                </vg-player>
            </div> -->
        </div>

    </div>

    <div class="section-3-control d-flex flex-column">
        <div class="container">
            <img src="./assets/img/landing/img-sec-3.png" class="d-none d-md-block section-3-img" />
            <img src="./assets/img/landing/section-3-img-mobile.png" class="d-block d-md-none section-3-img" />
        </div>

    </div>

    <div class="section-4-control">
        <div class="container d-flex flex-column">
            <span class="text-header">
                NFT COLLECTIONS
            </span>

            <span class="text-desc">
                Each NFT in the Hotel de Mentía collection represents a new life of hotel guest. <br> The collectible artworks are created by the assembly 7 components that will tell you the background story of your guests.
            </span>

            <div class="grid-control">

                <div class="d-flex flex-column">

                    <img src="./assets/img/landing/TheNovelty.gif" class="vdo-gif">
                    <span class="text-collecton">Collection I</span>
                    <span class="text-name">THE NOVELTY</span>
                    <span class="text-total-nft">2,000 NFTs</span>
                    <span class="text-date">April 2022</span>
                </div>
                <div class="d-flex flex-column">

                    <img src="./assets/img/landing/TheInvitation.gif" class="vdo-gif">
                    <span class="text-collecton">Collection II</span>
                    <span class="text-name">THE INVITATION</span>
                    <span class="text-total-nft">2,000 NFTs</span>
                    <span class="text-date">June 2022</span>
                </div>
                <div class="d-flex flex-column">

                    <img src="./assets/img/landing/the-grandball.png" class="vdo-gif">
                    <span class="text-collecton">Collection III</span>
                    <span class="text-name">THE GRAND BALL</span>
                    <span class="text-total-nft">6,000 NFTs</span>
                    <span class="text-date">Coming soon</span>
                </div>
            </div>

            <div class="d-flex d-md-none">
                <ngb-carousel class="carousel-control" [showNavigationIndicators]="false">
                    <ng-template ngbSlide>
                        <div class="d-flex flex-column">
                            <img src="./assets/img/landing/TheNovelty.gif" class="vdo-gif">
                            <span class="text-collecton">Collection I</span>
                            <span class="text-name">THE NOVELTY</span>
                            <span class="text-total-nft">2,000 NFTs</span>
                            <span class="text-date">April 2022</span>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="d-flex flex-column">

                            <img src="./assets/img/landing/TheInvitation.gif" class="vdo-gif">
                            <span class="text-collecton">Collection II</span>
                            <span class="text-name">THE INVITATION</span>
                            <span class="text-total-nft">2,000 NFTs</span>
                            <span class="text-date">June 2022</span>
                        </div>
    
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="d-flex flex-column">

                            <img src="./assets/img/landing/the-grandball.png" class="vdo-gif">
                            <span class="text-collecton">Collection III</span>
                            <span class="text-name">THE GRAND BALL</span>
                            <span class="text-total-nft">6,000 NFTs</span>
                            <span class="text-date">Coming soon</span>
                        </div>
    
                    </ng-template>
                    
                  </ngb-carousel>
              
            </div>

        </div>
        <div class="divider-black-gradient"></div>
    </div>

    <div class="section-5-control">
        <div class="container d-flex flex-column">
            <span class="text-white"> 5 TRIBES</span>
            <span class="text-white-desc">Determined by how the character act when they are faced with problems</span>
            <ngb-carousel class="carousel-control d-none d-md-block" [showNavigationIndicators]="false">
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/human-1.png" class="tribe-img">
                                <img src="./assets/img/landing/human-2.png" class="tribe-img">
                                <img src="./assets/img/landing/human-3.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">HUMAN</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/ghost-1.png" class="tribe-img">
                                <img src="./assets/img/landing/ghost-2.png" class="tribe-img">
                                <img src="./assets/img/landing/ghost-3.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">GHOST</span>
                        </div>
                    </div>

                </ng-template>
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/ape-1.png" class="tribe-img">
                                <img src="./assets/img/landing/ape-2.png" class="tribe-img">
                                <img src="./assets/img/landing/ape-3.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">APE</span>
                        </div>
                    </div>

                </ng-template>
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/wolf-1.png" class="tribe-img">
                                <img src="./assets/img/landing/wolf-2.png" class="tribe-img">
                                <img src="./assets/img/landing/wolf-3.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">WOLF</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/goat-1.png" class="tribe-img">
                                <img src="./assets/img/landing/goat-2.png" class="tribe-img">
                                <img src="./assets/img/landing/goat-3.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">GOAT</span>
                        </div>
                    </div>
                </ng-template>
              </ngb-carousel>
           
            <ngb-carousel class="carousel-control d-block d-md-none" [showNavigationIndicators]="false">
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/human-2.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">HUMAN</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/ghost-3.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">GHOST</span>
                        </div>
                    </div>

                </ng-template>
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/ape-2.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">APE</span>
                        </div>
                    </div>

                </ng-template>
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/wolf-1.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">WOLF</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="tribe-control">
                        <div class="d-flex flex-column tribe-1-control">

                            <div class="tribe-grid">
                                <img src="./assets/img/landing/goat-3.png" class="tribe-img">
                            </div>
                            <span class="tribe-text">GOAT</span>
                        </div>
                    </div>
                </ng-template>
              </ngb-carousel>
           


        </div>

        <div class="divider-black-gradient"></div>
    </div>
    <div class="section-6-control d-flex flex-column">

        <div class="container d-flex flex-column my-auto">
            <span class="text-white-invite">HOTEL PARTNER</span>
            <img src="./assets/img/landing/button-partner.png"  routerLink="/coming-soon" class="button-partner">
        </div>
    </div>


    <!-- <div class="section-8-control d-flex flex-column">

        <div class="container d-flex flex-column">


            <span class="text-header">
                CONCIERGE
            </span>

            <div class="grid-control">

                <div class="item-control">
                    <img src="./assets/img/landing/team-1.png" class="profile-img">
                    <span class="profile-name">RIETY</span>
                    <span class="profile-position">Founder & Main Artist</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/landing/team-2.png" class="profile-img">
                    <span class="profile-name">PERAKARN</span>
                    <span class="profile-position">Project Manager</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/landing/team-3.png" class="profile-img">
                    <span class="profile-name">FLINTSTONE</span>
                    <span class="profile-position">Community Manager</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/landing/team-4.png" class="profile-img">
                    <span class="profile-name">GHOZT</span>
                    <span class="profile-position">Blockchain Developer</span>
                </div>
            </div>
            <div class="grid-control">

                <div class="item-control">
                    <img src="./assets/img/landing/team-5.png" class="profile-img">
                    <span class="profile-name">ZTEVE</span>
                    <span class="profile-position">Blockchain Developer</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/landing/team-6.png" class="profile-img">
                    <span class="profile-name">DOW</span>
                    <span class="profile-position">Game Developer</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/landing/team-7.png" class="profile-img">
                    <span class="profile-name">FONE</span>
                    <span class="profile-position">Designer & 3D Generalist</span>
                </div>
                <div class="item-control">
                    <img src="./assets/img/landing/team-8.png" class="profile-img">
                    <span class="profile-name">HOUSE</span>
                    <span class="profile-position">Sound Creative & Social Media Manager</span>
                </div>
            </div>
        </div>
    </div> -->

    <div class="section-9-control">

        <div class="container d-flex flex-column">
            <span class="text-white">MORE ABOUT PROJECT</span>
            <img src="./assets/img/landing/button-lite-paper.png" (click)="clickLitePaper()"
                class="lite-paper-button mx-auto">
            <app-footer></app-footer>


       
        </div>

    </div>
</div>